import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
import StepsBlock from "src/components/steps-block";
import ExtrudedJumbotron from "src/components/extruded-jumbotron";
import LinkBanner from "src/components/link-banner";
import Banner from "src/components/banner";
import { DemoPitchImage } from "src/components/images";
import { BigStats, LinkButton } from "src/components/styled/sitewide";
import Tiles from "src/components/tiles";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeading title={props.pageContext.frontmatter.title} image="sketch" crumbs={["TechSlice", "Get your medical technology solutions to the market"]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      marginTop: "2rem",
      marginBottom: "2rem"
    }}>
      <div style={{
        display: "flex",
        gap: "2rem",
        flexWrap: "wrap",
        alignItems: "flex-start"
      }}>
        <div style={{
          flexGrow: 3,
          width: 400,
          maxWidth: "100%"
        }}>
          <h2 style={{
            color: "var(--primary)",
            fontSize: "2rem",
            fontWeight: 600,
            marginTop: 0
          }}>MedTech Accelerator - Get your product to market with a medtech startup <strong>acceleration program</strong></h2>
          <div style={{
            height: 2,
            maxWidth: 200,
            backgroundColor: "var(--background-alternate)"
          }}></div>
          <p>{`Our Mission at TechSlice is to build software that improves and saves lives. We accomplish this in several ways. Our team seeks out tech clients who are working on impact solutions that need a specialized software component. And we also seek out and help develop novel concepts that spring out of the medical field, and which need deeper nurturing. We are THE reference for medical technology startups wishing to accelerate their development.`}</p>
          <h3>{`TechSlice’s MedTech Venture Studio Model`}</h3>
          <p>{`Our sweat equity venture capital model allows us to work closely with entrepreneurs to turn ideas into successfully launched products in today’s marketplace. While our focus is on the software platforms and integration for the product, we pull in expertise on `}<strong parentName="p">{`Market Research, UX, Marketing, Branding, Strategy, Hardware, Compliance and Product Launch.`}</strong>{` This allows medtech startups to bootstrap their innovative product ideas without sacrificing quality or compliance standards. Building out novel products in a secure fashion, the right time around leads to greater scalability, market integration, and reduces technical debt.`}</p>
          <Link style={{
            color: "var(--strong)"
          }} to="/medtech-accelerator/application" mdxType="Link">Apply today!</Link>
          <h2>{`Who is our MedTech accelerator for?`}</h2>
          <p>{`TechSlice’s sweat equity is designed for medical professionals with an idea for making things better. We partner with surgeons, clinicians or technicians who want to drive positive change in care, treatment, therapies, research, or device enhancements in healthcare but need assistance in getting their concept developed and launched. From early investment to product build, testing, and deployment, TechSlice is a hyper-qualified team of engineers and developers with the knowledge to help build your product company.`}</p>
          <h2>{`What does an accelerator program do for you?`}</h2>
          <p>{`The TechSlice Accelerator Program helps you realize your vision and develop a solution that impacts and saves lives. We lift the burden of initial funding and technological expertise off your shoulders and work with you to launch a viable product quickly and effectively so you can get back to your areas of expertise and build the life you dream about.`}</p>
        </div>
        <StepsBlock style={{
          flexGrow: 2,
          width: 400,
          maxWidth: "100%"
        }} header={<h3 style={{
          textAlign: "center",
          marginBottom: "2rem"
        }}>
    Join the TechSlice Portfolio of MedTech Partners
  </h3>} link="/medtech-accelerator/application" linkTitle="Apply Today!" steps={[{
          title: "Consultation",
          description: <p>Consultation is followed by an <strong>Evaluation &amp; Second Discovery</strong> meeting: this is where we poke holes and brainstorm, layer in requirements for research, development and market viability.</p>
        }, {
          title: "Proposal",
          description: <p>Here we fine tune the discovery information and distill down the market research and development steps required, then deliver that up in a <strong>Market Evaluation &amp; Proposal.</strong></p>
        }, {
          title: "Get to Work!",
          description: <p><strong>This is the development phase:</strong> Our team collaborates on the development of your vision. <strong>POC, or MVP, to prototype development and Release v1.0. And we don’t stop there. After the version 1 release we work through product integration in the live environment.</strong> This is followed by ongoing O&M, development support, updates, and knowledge transfer designed to make a smooth product launch and ongoing product success.</p>
        }]} mdxType="StepsBlock"></StepsBlock>
      </div>
    </div>
    <div style={{
      position: "relative",
      marginTop: "6rem",
      marginBottom: "-8rem",
      paddingBottom: "12rem",
      backgroundColor: "var(--background-alternate)"
    }}>
      <Banner mdxType="Banner">
        <h2 style={{
          fontWeight: 600
        }}>Benefits Of The MedTech Acceleration Program</h2>
      </Banner>
      <div className="container">
        <p>{`TechSlice’s sweat equity model combines TechSlice's competitive pricing, technical competency, and trust: we understand and can carry out ambitious ideas. TechSlice offers below market rates for an equity percentage and stronger technical competency for development needs than industry average bill rates. Trust in competency and value based funding are the two main factors why clients decide to work with us.`}</p>
        <Tiles mdxType="Tiles"></Tiles>
      </div>
    </div>
    <ExtrudedJumbotron image="abstract" mdxType="ExtrudedJumbotron">
      <div className="container" style={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: "4rem"
      }}>
        <div style={{
          flexGrow: 1,
          width: "400px",
          maxWidth: "calc(100vw - 3rem)"
        }}>
          <DemoPitchImage mdxType="DemoPitchImage"></DemoPitchImage>
        </div>
        <div style={{
          flexGrow: 1,
          width: "400px",
          maxWidth: "calc(100vw - 3rem)"
        }}>
          <h3 style={{
            fontSize: "2rem",
            marginBottom: "1rem",
            color: "var(--primary)"
          }}>Pitch Your MedTech Startup at a Demo Day</h3>
          <div style={{
            height: 1,
            maxWidth: 200,
            backgroundColor: "rgba(255, 255, 255, 0.5)"
          }}></div>
          <p>{`Apply to present at an upcoming TechSlice Demo Day program which includes access to: investors, potential funding, potential legal support allowances and a limited-scope business administrative support.`}</p>
          <p>{`Demo Day is designed to directly support client software development and enables TechSlice to leverage investor knowledge to inform more accurate go/no-go decisions and market valuation criteria for projects we work on. `}</p>
          <p>{`TechSlice has the right connections, the right development team, and the right expertise to elevate your project from idea to launch.`}</p>
          <h4>{`How To Apply`}</h4>
          <ol>
            <li parentName="ol">
              <Link style={{
                color: "var(--primary)"
              }} to="/medtech-accelerator/demo-day-application" mdxType="Link">Apply Online</Link>
            </li>
            <li parentName="ol">
              <p parentName="li">{`Response (our team will review your application and get back to you)`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li">{`Availability Match & Schedule (As we evaluate our demo day investors, project interests and available presentation slots, we will guide you to the best match day and schedule your pitch!)`}</p>
            </li>
          </ol>
          <LinkButton to="/events/" mdxType="LinkButton">View Upcoming Demo Days</LinkButton>
        </div>
      </div>
    </ExtrudedJumbotron>
    <div className="container" style={{
      marginTop: "4rem",
      marginBottom: "4rem"
    }}>
      <h3 style={{
        color: "var(--primary)",
        fontSize: "2rem",
        fontWeight: 600,
        marginBottom: 0,
        textAlign: "center"
      }}>Demo Day</h3>
      <p>{`As we have been working on very exciting, innovative software development projects, we have identified that our clients struggle to `}<strong parentName="p">{`connect with the right investors and to obtain the next level of funding`}</strong>{`.`}</p>
      <p>{`We have designed a program to help new and existing clients meet this need by hosting multiple Client & Investor Demo Day.`}</p>
      <h2 style={{
        color: "var(--primary)",
        fontSize: "2rem",
        fontWeight: 600,
        marginBottom: 0,
        textAlign: "center"
      }}>Get involved with TechSlice today!</h2>
      <p>{`We provide early stage medical devices and healthcare technology startups and innovators with an option to reduce total project compensation/cost and the resources they need in exchange for a small percentage of equity. Working with TechSlice allows businesses to develop a strong business model and development plan for bringing their medical technology solution to the market.`}</p>
    </div>
    <LinkBanner header="Ready To Get Started?" paragraph="TechSlice wants to partner with you." link="/medtech-accelerator/application" linkTitle="Apply Today!" mdxType="LinkBanner"></LinkBanner>
    <section className="container" style={{
      marginTop: "4rem",
      marginBottom: "4rem"
    }}>
      <h3>{`Learn more about our medtech accelerator program for medtech startups`}</h3>
      <h2>{`What are medtech accelerators?`}</h2>
      <p>{`Medtech accelerator programs provide startup level innovators in the healthcare industry with the different resources and capital (sweat equity) required to succeed: funding, coaching, mentoring, networking opportunities, business development support and customer acquisition. Gathering the brightest minds in the healthcare industry is the key to successfully developing healthcare solutions.`}</p>
      <p>{`Medtech accelerators have been a critical part of the technology economy and innovation ecosystem. There has been tremendous growth in medical technology accelerators across the nation supported by experts and investments of a diverse set of stakeholders, part of a larger innovation economy. TechSlice is proud to help innovators develop innovative solutions to improve patient care and health care delivery through value-based investments.`}</p>
      <h2>{`How do medtech accelerators work?`}</h2>
      <p>{`An accelerator program aims to help medical technology startups grow by helping them through the entire startup journey, from patents to clinical trials to market strategy. They usually take place for three to six months, during which they provide mentorship, funding, network opportunities, and access to potential partners. Medtech accelerator programs like TechSlice Entrepreneurs provide subject matter experts and capital during any and all phases of product development of medical technology or medical devices.`}</p>
      <p>{`A medical technology accelerator provides a variety of resources adapted to any business model, including financial assistance, technical expertise, and connections to corporate partners and potential customers. TechSlice's demo day allows medtech startups to present their ideas to a panel of investors who decide whether to invest in their healthcare business.`}</p>
      <h2>{`What product development processes are facilitated by TechSlice?`}</h2>
      <p>{`TechSlice can help medtech startups throughout the whole medtech development process, and is the perfect partner to bring your product to market as fast and safely as possible. We offer many additional resources to medtech startups to develop cutting-edge medical technology and devices.`}</p>
      <ol>
        <li parentName="ol">{`Patent research and intellectual property;`}</li>
        <li parentName="ol">{`Developing business formations;`}</li>
        <li parentName="ol">{`Finding investment partners and opportunities;`}</li>
        <li parentName="ol">{`Asset growth;`}</li>
        <li parentName="ol">{`Regulatory compliance;`}</li>
        <li parentName="ol">{`Marketing;`}</li>
        <li parentName="ol">{`Product design;`}</li>
      </ol>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      